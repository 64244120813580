import * as React from 'react';

import { cn } from '@/renderer/utils/cn';
import { randomSeed } from '@/renderer/utils/randomSeed';

interface CellBreakpoints {
  breakpoint?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
}

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement> & CellBreakpoints
>(({ className, breakpoint, ...props }, ref) => (
  <div className="relative flex w-full flex-row flex-nowrap overflow-x-auto">
    <table
      ref={ref}
      className={cn(
        'w-full caption-bottom text-sm',
        // these are probably useless
        breakpoint === 'sm'
          ? 'group/table-sm sm:min-w-[300px]'
          : breakpoint === 'md'
            ? 'group/table-md md:min-w-[340px]'
            : breakpoint === 'lg'
              ? 'group/table-lg lg:min-w-[500px]'
              : breakpoint === 'xl'
                ? 'group/table-xl xl:min-w-[900px]'
                : breakpoint === '2xl'
                  ? 'group/table-2xl 2xl:min-w-[1200px]'
                  : breakpoint === '3xl'
                    ? 'group/table-3xl 3xl:min-w-[1600px]'
                    : breakpoint === '4xl'
                      ? 'group/table-4xl 4xl:min-w-[2100px]'
                      : breakpoint === '5xl'
                        ? 'group/table-5xl 5xl:min-w-[2900px]'
                        : '',
        className,
      )}
      {...props}
    />
  </div>
));

Table.displayName = 'Table';

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn('group/thead [&_tr]:border-b', className)} {...props} />
));

TableHeader.displayName = 'TableHeader';

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn('group/tbody border-0 [&_tr:last-child]:border-0', className)}
    {...props}
  />
));

TableBody.displayName = 'TableBody';

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn('bg-zinc-100 text-right dark:bg-zinc-800 [&_tr]:border-t', className)}
    {...props}
  />
));

TableFooter.displayName = 'TableFooter';

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & CellBreakpoints
>(({ className, breakpoint = '', ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      'group/tr border-zinc-900/5 border-b transition-colors dark:border-white/10',
      breakpoint === 'sm'
        ? 'group/sm max-sm:flex max-sm:flex-col max-sm:group-[&]/thead:flex-row max-sm:group-[&]/thead:flex-wrap'
        : breakpoint === 'md'
          ? 'group/md max-md:flex max-md:flex-col max-md:group-[&]/thead:flex-row max-md:group-[&]/thead:flex-wrap'
          : breakpoint === 'lg'
            ? 'group/lg max-lg:flex max-lg:flex-col max-lg:group-[&]/thead:flex-row max-lg:group-[&]/thead:flex-wrap'
            : breakpoint === 'xl'
              ? 'group/xl max-xl:flex max-xl:flex-col max-xl:group-[&]/thead:flex-row max-xl:group-[&]/thead:flex-wrap'
              : breakpoint === '2xl'
                ? 'group/2xl max-2xl:flex max-2xl:flex-col max-2xl:group-[&]/thead:flex-row max-2xl:group-[&]/thead:flex-wrap'
                : breakpoint === '3xl'
                  ? 'group/3xl max-3xl:flex max-3xl:flex-col max-3xl:group-[&]/thead:flex-row max-3xl:group-[&]/thead:flex-wrap'
                  : breakpoint === '4xl'
                    ? 'group/4xl max-4xl:flex max-4xl:flex-col max-4xl:group-[&]/thead:flex-row max-4xl:group-[&]/thead:flex-wrap'
                    : breakpoint === '5xl'
                      ? 'group/5xl max-5xl:flex max-5xl:flex-col max-5xl:group-[&]/thead:flex-row max-5xl:group-[&]/thead:flex-wrap'
                      : 'group/normal',
      className,
    )}
    {...props}
  />
));

TableRow.displayName = 'TableRow';

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement> & CellBreakpoints
>(({ className, breakpoint, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      'group/th whitespace-nowrap border-zinc-900/10 border-b bg-zinc-50 text-left align-middle font-medium text-zinc-500 dark:border-white/10 dark:bg-transparent dark:text-zinc-300 [&:has([role=checkbox])]:pr-0',
      // if TR has breakpoint set, use that to determine if we should unhide the cell headings
      breakpoint === 'sm'
        ? 'max-sm:hidden max-sm:group-[&]/sm:table-cell'
        : breakpoint === 'md'
          ? 'max-sm:group-[&]/sm:table-cell max-md:hidden max-md:group-[&]/md:table-cell max-xl:group-[&]/xl:table-cell'
          : breakpoint === 'lg'
            ? 'max-sm:group-[&]/sm:table-cell max-lg:hidden max-lg:group-[&]/lg:table-cell max-xl:group-[&]/xl:table-cell'
            : breakpoint === 'xl'
              ? 'max-sm:group-[&]/sm:table-cell max-xl:hidden max-xl:group-[&]/xl:table-cell'
              : breakpoint === '2xl'
                ? 'max-sm:group-[&]/sm:table-cell max-xl:group-[&]/xl:table-cell max-2xl:hidden max-2xl:group-[&]/2xl:table-cell'
                : breakpoint === '3xl'
                  ? 'max-sm:group-[&]/sm:table-cell max-xl:group-[&]/xl:table-cell max-2xl:group-[&]/2xl:table-cell max-3xl:hidden max-3xl:group-[&]/3xl:table-cell'
                  : breakpoint === '4xl'
                    ? 'max-sm:group-[&]/sm:table-cell max-xl:group-[&]/xl:table-cell max-2xl:group-[&]/2xl:table-cell max-4xl:hidden max-3xl:group-[&]/3xl:table-cell max-4xl:group-[&]/4xl:table-cell'
                    : breakpoint === '5xl'
                      ? 'max-sm:group-[&]/sm:table-cell max-xl:group-[&]/xl:table-cell max-2xl:group-[&]/2xl:table-cell max-5xl:hidden max-3xl:group-[&]/3xl:table-cell max-4xl:group-[&]/4xl:table-cell max-5xl:group-[&]/5xl:table-cell'
                      : '',
      className,
    )}
    {...props}
  />
));

TableHead.displayName = 'TableHead';

const TableCell = React.forwardRef<
  HTMLTableCellElement | HTMLAnchorElement,
  React.TdHTMLAttributes<HTMLTableCellElement> &
    React.AnchorHTMLAttributes<HTMLAnchorElement> &
    CellBreakpoints
>(
  (
    { className, href, breakpoint = '', ...props },
    ref,
    TdComponent: React.ElementType<any, 'a' | 'td'> = href ? 'a' : 'td',
  ) => (
    <TdComponent
      ref={ref}
      href={href}
      className={cn(
        'before:-ml-2 break-words px-4 py-4 align-middle before:block before:font-semibold before:content-[attr(title)] empty:hidden dark:text-zinc-400 [&:has([role=checkbox])]:pr-0',
        TdComponent === 'a' && 'table-cell text-inherit',
        // if TR has breakpoint set, use that to determine if we should hide the cell titles
        '3xl:group-[&]/3xl:before:content-none 4xl:group-[&]/4xl:before:content-none 5xl:group-[&]/5xl:before:content-none group-[&]/normal:before:content-none sm:group-[&]/sm:before:content-none md:group-[&]/md:before:content-none lg:group-[&]/lg:before:content-none xl:group-[&]/xl:before:content-none 2xl:group-[&]/2xl:before:content-none',
        'max-sm:group-[&]/sm:py-1 max-md:group-[&]/xl:py-1 max-lg:group-[&]/xl:py-1 max-xl:group-[&]/xl:py-1 max-2xl:group-[&]/2xl:py-1 max-3xl:group-[&]/3xl:py-1 max-4xl:group-[&]/4xl:py-1 max-5xl:group-[&]/5xl:py-1',
        breakpoint === 'sm'
          ? 'max-sm:hidden sm:empty:table-cell'
          : breakpoint === 'md'
            ? 'max-md:hidden md:empty:table-cell'
            : breakpoint === 'lg'
              ? 'max-lg:hidden lg:empty:table-cell'
              : breakpoint === 'xl'
                ? 'max-xl:hidden xl:empty:table-cell'
                : breakpoint === '2xl'
                  ? 'max-2xl:hidden 2xl:empty:table-cell'
                  : breakpoint === '3xl'
                    ? '3xl:empty:table-cell max-3xl:hidden'
                    : breakpoint === '4xl'
                      ? '4xl:empty:table-cell max-4xl:hidden'
                      : breakpoint === '5xl'
                        ? '5xl:empty:table-cell max-5xl:hidden'
                        : 'empty:table-cell',
        className,
      )}
      {...props}
    />
  ),
);

TableCell.displayName = 'TableCell';

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn(
      'mt-4 border-zinc-900/5 p-4 font-semibold text-sm text-zinc-500 dark:border-white/10 dark:text-zinc-400',
      className,
    )}
    {...props}
  />
));

TableCaption.displayName = 'TableCaption';

function TableSkeletonContent({
  className = undefined,
  rows = 1,
  breakpoint,
  widths,
}: {
  className?: string;
  rows?: number;
  widths: (
    | number
    | string
    | [number, number]
    | [number, number, CellBreakpoints['breakpoint']]
    | `${string};${CellBreakpoints['breakpoint']}`
  )[];
} & CellBreakpoints) {
  return (
    <TableBody className={className}>
      {new Array(rows).fill(1).map((_, ridx) => (
        <TableRow key={ridx} breakpoint={breakpoint}>
          {widths.map((width, idx) => (
            <TableCell
              key={idx}
              breakpoint={
                typeof width === 'string'
                  ? (width.split(';')[1] as CellBreakpoints['breakpoint'])
                  : Array.isArray(width)
                    ? width[2]
                    : undefined
              }
            >
              {typeof width === 'string' ? (
                <div className="inline-block h-4 animate-pulse select-none overflow-hidden whitespace-nowrap rounded-sm bg-zinc-200 text-transparent dark:bg-zinc-700">
                  {width.split(';')[0]}
                </div>
              ) : (
                <div
                  className="inline-block h-4 w-[var(--shadowwidth)] animate-pulse rounded-sm bg-zinc-200 dark:bg-zinc-700"
                  style={
                    {
                      '--shadowwidth': Array.isArray(width)
                        ? `${(
                            width[0] + randomSeed(`${ridx}.${idx}:${width}`) * (width[1] - width[0])
                          ).toFixed(2)}ch`
                        : `${width.toFixed(2)}ch`,
                    } as React.CSSProperties
                  }
                />
              )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}

export {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
  TableSkeletonContent,
};
